/* eslint-disable import/no-cycle */
import { Box, Stack } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useEffect, useRef } from 'react';
import { treatmentsQuestions } from '../stepsData';

import useLocales from '../../../../../../lib/hooks/useLocales';
import RHFStyledRadioGroup from '#/components/shared/hook-form/RHFStyledRadioGroup';

const Treatments = () => {
  const {
    formState: { errors },
  } = useFormContext();

  const refs = useRef<{ [key: string]: HTMLElement | null }>({});

  const { translate } = useLocales();

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      const errorKeys = Object.keys(errors);

      const errorElements = errorKeys
        .map((key) => refs.current[key])
        .filter((ref) => ref !== undefined && ref !== null);

      if (errorElements.length > 0) {
        const highestErrorElement = errorElements.reduce((highest, current) => {
          const currentTop = current ? current.getBoundingClientRect().top : 0;
          const highestTop = highest ? highest.getBoundingClientRect().top : 0;

          return currentTop < highestTop ? current : highest;
        }, errorElements[0]);

        if (highestErrorElement) {
          highestErrorElement.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'center',
          });
        }
      }
    }
  }, [errors]);

  const translatedQuestions = treatmentsQuestions.map((question, i) => ({
    ...question,
    question: translate(`livingWill.stepThree.questions.${i}.question`),
    answers: question.answers.map((answer, j) => ({
      ...answer,
      label: translate(
        `livingWill.stepThree.questions.${i}.options.${j}.label`
      ),
    })),
  }));

  return (
    <Box sx={{ width: '100%' }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {/* <ReactPlayer */}
        {/*  url="https://youtu.be/iNdK6IapPcQ" */}
        {/*  playing */}
        {/*  light="/assets/images/youtubeThumbnail.webp" */}
        {/*  width={670} */}
        {/* /> */}
      </Box>
      <Stack spacing={5}>
        {translatedQuestions.map((question, index) => (
          <div
            key={question.value}
            ref={(ref) => {
              refs.current[`${question.value}`] = ref;
            }}
          >
            <Stack direction="column">
              {question.answers && (
                <RHFStyledRadioGroup
                  name={question.value}
                  question={{
                    questionKey: question.value,
                    question: question.question,
                    answers: question?.answers?.map((answer) => ({
                      value: answer.value,
                      label: answer.label,
                    })),
                  }}
                />
              )}
            </Stack>
          </div>
        ))}
      </Stack>
    </Box>
  );
};

export default Treatments;
